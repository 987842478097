<template>
  <div>
    <div class="blank-box" id="scroll">
      <iframe
        :src="web_content"
        frameborder="0"
        v-if="module_type == 2"
      ></iframe>
      <div style="min-height: 95%" v-if="module_type == 1">
        <van-empty
          :image="require('@/assets/null.svg')"
          v-if="web_content == ''"
        />
        <div class="blank" v-html="web_content"></div>
      </div>
      <Support v-if="module_type == 1"></Support>
    </div>
    <sideBar></sideBar>
  </div>
</template>

<script>
import sideBar from "@/components/sidebar";
import Support from "@/components/support";
export default {
  components: {
    sideBar,
    Support,
  },
  data() {
    return {
      web_content: "",
      module_type: 1,
    };
  },
  created() {
    this.$route.query.module_type == 2 ? (this.module_type = 2) : "";
    this.module_type == 1 ? (this.web_content = JSON.parse(  window.sessionStorage.getItem("module_value") ).web_content)
      : (this.web_content = JSON.parse( window.sessionStorage.getItem("module_value") ).off_site_links);
  },
};
</script>
<style lang="less" scoped>
/deep/.van-empty,
/deep/.van-loading {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.van-empty {
  width: 100%;
  .van-empty__image {
    width: 300px;
    height: 300px;
  }
}
/deep/.van-loading {
  top: 45%;
}
iframe {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.blank-box {
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  word-break: break-all;
  overflow-y: scroll;
  // &::-webkit-scrollbar {
  //             display: none;
  //           }
}
.blank {
  // padding: .1rem .15rem;
  font-size: 0.14rem;
  /deep/table{
      border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  /deep/table tbody th{
    border-bottom: 2px solid #ccc;
    text-align: center;
    background-color: #f1f1f1;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
  }
  /deep/table tbody td,
  /deep/table tbody td{
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
  }
}
</style>
